import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from './firebaseConfig.js';
import { signInWithEmailAndPassword } from "firebase/auth";
import "./SignIn.css"

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInError, setSignInError] = useState(null); // State to hold sign-in error
  const [userSignedIn, setUserSignedIn] = useState(false); // State to track if user is signed in

  // Function to handle sign-in
  const handleSignIn = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // User signed in successfully
        const user = userCredential.user;
        console.log("User signed in:", user);
        // Update state to indicate user is signed in
        setUserSignedIn(true);
        // Store credentials in local storage
        localStorage.setItem('userCredentials', JSON.stringify({ email, password }));
        // Redirect to tasks page
        window.location.href = "/tasks"; // You may need to adjust this based on your routing setup
      })
      .catch((error) => {
        // Handle sign-in errors
        console.error("Error signing in:", error);
        switch (error.code) {
          case "auth/email-already-in-use":
            setSignInError("This email is already in use. Please sign in with a different email or reset your password.");
            break;
          case "auth/user-not-found":
            setSignInError("It seems you don't have an account yet. Please sign up first.");
            break;
          case "auth/invalid-credential":
            setSignInError("Invalid credentials. Please check your email and password and try again.");
            break;
          // Add custom error messages for other error codes as needed
          default:
            setSignInError(error.message); // Set sign-in error message in state
            break;
        }
      });
  };

  useEffect(() => {
    // Check if user credentials are stored in local storage
    const storedCredentials = localStorage.getItem('userCredentials');
    if (storedCredentials) {
      const { email, password } = JSON.parse(storedCredentials);
      // Attempt sign-in with stored credentials
      handleSignIn(email, password);
    }
  }, []); // Run only once after component mount

  return (
    <div className="signin-form">
      {signInError && <p className="signin-error">{signInError}</p>} {/* Display sign-in error message if any */}
      <h1>Let´s reach some goals today</h1>
      <h3>Login to get back to your tasks</h3>
      <div>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="signin-input" />
      </div>
      <div>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="signin-input" />
      </div>
      <button onClick={() => handleSignIn(email, password)} className="signin-button">Sign In</button>
      {/* Hide the button until user is signed in */}
      {userSignedIn && <Link to="/tasks" className="signin-link">Go to Tasks</Link>}
      <div style={{ textAlign: 'right' }}>
        <Link to="/signup" style={{ color: 'white', fontSize: '12px' }}>Sign Up</Link>
      </div>
    </div>
  );
}

export default SignIn;
