// firebaseConfig.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc, collection } from "firebase/firestore"; 



const firebaseConfig = {
  apiKey: "AIzaSyCMBCfqxZGmtyoPS93liSNs-naR5wLHW-0",
  authDomain: "bossai-5fb7e.firebaseapp.com",
  projectId: "bossai-5fb7e",
  storageBucket: "bossai-5fb7e.appspot.com",
  messagingSenderId: "315335863761",
  appId: "1:315335863761:web:edec6e056c144c096744f0",
  measurementId: "G-85Z864D2FZ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app); // Initialize Firestore

export { app, analytics, auth, firestore };