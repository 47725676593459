import React from 'react';
import SignIn from '../components/SignIn';
import Header from '../components/Header.js';
import './SignInPage.css'; // Import the CSS file for styling

function SignInPage() {
  return (
    <div>
        <Header/>
    <div className="signin-page-container"> {/* Add a class for styling */}
  
      <SignIn />
    </div>
    </div>
  );
}

export default SignInPage;
