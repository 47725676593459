// App.js

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TaskListPage from './pages/TaskListPage';
import TaskListPageWorking from './pages/TaskListPageWorking';
import SettingsPage from './pages/SettingsPage';
import SignUpPage from './pages/SignUpPage'; // Import the SignUpPage component
import SignInPage from './pages/SignInPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/tasks" element={<TaskListPage />} />
        <Route path="/tasks2" element={<TaskListPageWorking />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/signup" element={<SignUpPage />} /> {/* Add this route for the sign-up page */}
        <Route path="/signin" element={<SignInPage />} /> {/* Add this route for the sign-up page */}
      </Routes>
    </Router>
  );
}

export default App;
