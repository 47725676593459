import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header.js'; 

function SettingsPage() {
  return (
    <div>
        <Header/>
      <h1>Settings</h1>
      <p>Configure your Procrastination Boss settings here.</p>
    </div>
  );
}

export default SettingsPage;
