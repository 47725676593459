import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header.js';
import './HomePage.css'; // Import your custom CSS file for styling

function HomePage() {
  return (
    <div>
         <Header />
             
   
      <div className="content-container">
      <img src="https://i.imgur.com/BidwL4C.png" alt="Company Logo" className="logo2  " />
        <h1>Welcome to BossAI</h1>
        <p>Start pushing your tasks, beating procrastination and achieving your goals</p>
       
        <Link to="/signin" className="btn">Let's Start</Link>
        <p>BossAI is still in development, much to do</p>
    </div>
    
    </div>
  );
}

export default HomePage;
