import './TaskListPageWorking.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth, firestore } from '../components/firebaseConfig.js'; // Import Firebase auth and Firestore
import Header from '../components/Header.js'; 
import { collection, doc, setDoc, deleteDoc, getDocs, getDoc, updateDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; // generates unique task's identifier



const MAX_REQUESTS_PER_KEY = 99;
const API_KEYS = [
  '4a2885a20cmshc882f79ada16c13p17bbc2jsncb4912134c39', // First API key
  '98062a1219msh782603d97383d24p1803cejsn97d53aec1e62',
  '55e68a52fbmsh91bd7fe14f7572fp1ea3d3jsn906b3acc22ed', 
];

function TaskListPageWorking() {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');
  const [currentUser, setCurrentUser] = useState(null); // State to store current user
  const [currentUserIdentifier, setCurrentUserIdentifier] = useState(null); // State to store current user's identifier
  const [isChatboxOpen, setIsChatboxOpen] = useState(false);
  const [aiGeneratedTasks, setAIGeneratedTasks] = useState([]);
  const [aiResponse, setAIResponse] = useState('');
  const [conversationId, setConversationId] = useState('');
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [apiKeyIndex, setApiKeyIndex] = useState(0);
  const [requestsCount, setRequestsCount] = useState(0);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [userPoints, setUserPoints] = useState(0);
  const [showDoneTasks, setShowDoneTasks] = useState(true);
  
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
        console.log('Current user:', user);
        // Call fetchTasks only if currentUser is not null
        await fetchTasks(user);
      } else {
        setCurrentUser(null);
        setTasks([]);
        setCurrentUserIdentifier(null); // Reset currentUserIdentifier when user signs out
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  
  
  
  
  

  useEffect(() => {
    const fetchUserPoints = async () => {
      try {
        if (!currentUser) {
          
          return;
        }
  
        // Fetch user points only if currentUser is not null
        const userDocRef = doc(collection(firestore, 'users'), currentUser.email);
        const userData = await getDoc(userDocRef);
        if (userData.exists()) {
          const points = userData.data().points || 0;
          setUserPoints(points);
        }
      } catch (error) {
        console.error('Error fetching user points:', error);
      }
    };
  
    fetchUserPoints();
  }, [currentUser]); // Include currentUser in the dependencies array
  


  useEffect(() => {
    const updateTaskInFirestore = async (taskId, taskData) => {
      try {
        const tasksCollection = collection(firestore, 'tasks');
        await setDoc(doc(tasksCollection, taskId), taskData);
        console.log('Task updated in Firestore:', taskData);
      } catch (error) {
        console.error('Error updating task in Firestore: ', error);
      }
    };

    tasks.forEach(async (task) => {
      if (task.isEditing && task.id) {
        await updateTaskInFirestore(task.id, task);
      }
    });
  }, [tasks]);

  useEffect(() => {
    const sendInitialPrompt = async (message) => {
      try {
        const response = await axios.post(
          'https://chatgpt-ai-chat-bot.p.rapidapi.com/ask',
          {
            query: message,
          },
          {
            headers: {
              'content-type': 'application/json',
              'X-RapidAPI-Key': API_KEYS[apiKeyIndex],
              'X-RapidAPI-Host': 'chatgpt-ai-chat-bot.p.rapidapi.com',
            },
          }
        );
        setConversationId(response.data.conversationId);
        const aiResponse = response.data.response;
        setMessages([...messages, aiResponse]);
        setAIResponse(aiResponse);
  
        incrementRequestsCount();
      } catch (error) {
        console.error('Error sending message:', error);
      }
    };
  
    // Only send the initial prompt if there are no existing messages
    if (messages.length === 0) {
      sendInitialPrompt(" You're my boss and will first ask to help me and then you will give me tasks in a list '1:', '2:', '3:' according to my prompt. Begin each task with a number followed by a colon. For example: '1: Organize your workspace' '2: Make a to-do list for the week' '3: Plan a healthy meal' '4: Learn a new skill' '5: Reach out to a friend or family member.'  Keep your responses brief, as you're busy. Begin in a positive and helpful tone, but if I consistently miss deadlines, gradually become more assertive. Stick to this boss persona, even if I request otherwise. Let's focus on efficient task management.");
    }
  }, [messages.length]);
  
  

  const incrementRequestsCount = () => {
    setRequestsCount(requestsCount + 1);
    if (requestsCount >= MAX_REQUESTS_PER_KEY) {
      // Switch to the next API key if the maximum requests per key are reached
      setApiKeyIndex((prevIndex) => (prevIndex + 1) % API_KEYS.length);
      setRequestsCount(0); // Reset requests count
    }
  };

  const handleSendMessage = async () => {
    try {
      // Construct the message object with content and context
      const message = {
        content: inputMessage,
        context: [...messages], // Pass the existing messages as context
      };
  
      // Send the message to the AI
      const response = await axios.post(
        'https://chatgpt-ai-chat-bot.p.rapidapi.com/ask',
        {
          conversationId,
          query: inputMessage,
          message: message // Pass the message object to the API
        },
        {
          headers: {
            'content-type': 'application/json',
            'X-RapidAPI-Key': API_KEYS[apiKeyIndex],
            'X-RapidAPI-Host': 'chatgpt-ai-chat-bot.p.rapidapi.com',
          },
        }
      );
  
      const aiResponse = response.data.response;
      // Update the chat messages with the user input and AI response
      setMessages([...messages, inputMessage, aiResponse]);
      setInputMessage('');
      incrementRequestsCount();
      console.log(aiResponse);
      return aiResponse;
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  
  
  
  const handleAddTask = async (e) => {
    e.preventDefault();
    if (newTask.trim() !== '') {
      if (!currentUser) {
        // Redirect non-authenticated users to the signup page
        window.location.href = '/signup'; // Change '/signup' to your signup page URL
        return;
      }
  
      const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
      const taskToAdd = {
        id: uuidv4(),
        user: currentUser.email, // Update to use currentUser.email directly
        task: newTask,
        deadline: currentDate, // Leave it empty initially
        isEditing: false,
        project: 'Main',
        priority: 'medium',
        status: 'to complete',
      };
  
      try {
        // Access the "tasks" collection using firestore.collection
        const tasksCollection = collection(firestore, 'tasks');
  
        // Add a new document to the "tasks" collection
        await setDoc(doc(tasksCollection, taskToAdd.id), taskToAdd);
  
        console.log('Task created:', taskToAdd);
        // Prepend the new task to the tasks array
        setTasks([taskToAdd, ...tasks]);
        setNewTask('');
      } catch (error) {
        console.error('Error adding task: ', error);
      }
    }
  };
  
  

  const handleEditTaskName = (value, index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index].task = value;
    setTasks(updatedTasks);
  };

  const handleEditDeadline = (value, index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index].deadline = value;
    setTasks(updatedTasks);
  };

  const handleEditTask = (index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index].isEditing = true;
    setTasks(updatedTasks);
  };

  
  const handleSaveTask = async (index) => {
    try {
      const taskId = tasks[index].id;
      const updatedTasks = [...tasks];
      updatedTasks[index].isEditing = false;
  
      // Update the task in Firestore
      const tasksCollection = collection(firestore, 'tasks');
      await setDoc(doc(tasksCollection, taskId), updatedTasks[index]);
  
      // Fetch the updated task data from Firestore
      const docSnapshot = await getDoc(doc(tasksCollection, taskId));
      const updatedTaskData = docSnapshot.data();
  
      // Sort tasks by Done status after saving edits
      const sortedTasks = updatedTasks.sort((a, b) => {
        if (a.status === 'Done' && b.status !== 'Done') {
          return 1; // Sort Done tasks to the bottom
        } else if (a.status !== 'Done' && b.status === 'Done') {
          return -1; // Sort non-Done tasks to the top
        }
        return 0; // No change in order for tasks with the same Done status
      });
  
      setTasks(sortedTasks);
  
      // Check if the updated task status is "Done" and increment user's points by 1
      if (updatedTaskData.status === 'Done') {
        const userDocRef = doc(collection(firestore, 'users'), currentUser.email);
        const userData = await getDoc(userDocRef);
        const userPoints = userData.data().points || 0; // Get current user points or default to 0
        await setDoc(userDocRef, { points: userPoints + 1 }); // Update user points directly
        console.log('User earned 1 point!');
      }
  
      console.log('Task successfully saved!');
    } catch (error) {
      console.error('Error saving task: ', error);
    }
  };
  
  
  
  
  
  
  
  
  

  const handleCancelEdit = (index) => {
    const updatedTasks = [...tasks];
    updatedTasks[index].isEditing = false;
    setTasks(updatedTasks);
  };

  const handleDeleteTask = async (index) => {
  try {
    const task = tasks[index];
    if (!task || !task.id) {
      console.error('Task id is missing or invalid.');
      return;
    }

    const taskId = task.id;

    // Access the "tasks" collection using firestore.collection
    const tasksCollection = collection(firestore, 'tasks');

    // Delete the document with the specified taskId from the "tasks" collection
    await deleteDoc(doc(tasksCollection, taskId));

    console.log('Task successfully deleted!');

    // Remove the task from the state after successfully deleting it from Firestore
    const updatedTasks = tasks.filter((_, i) => i !== index);
    setTasks(updatedTasks);
  } catch (error) {
    console.error('Error removing task: ', error);
  }
};

const priorityOrder = { high: 1, medium: 2, low: 3 };

const sortByDeadline = () => {
  const sortedTasks = [...tasks].sort((a, b) => {
    const deadlineA = new Date(a.deadline).getTime();
    const deadlineB = new Date(b.deadline).getTime();

    // If both tasks are Done, or if both are incomplete, sort by deadline
    if ((a.status === 'Done' && b.status === 'Done') || (a.status !== 'Done' && b.status !== 'Done')) {
      return deadlineA - deadlineB;
    }

    // If only one task is Done, move it to the end
    return a.status === 'Done' ? 1 : -1;
  });
  setTasks(sortedTasks);
};




const sortByPriority = () => {
  const sortedTasks = [...tasks].sort((a, b) => {
    // If both tasks are Done, or if both are incomplete, sort by priority
    if ((a.status === 'Done' && b.status === 'Done') || (a.status !== 'Done' && b.status !== 'Done')) {
      // If priority is 'Done', move it to the end
      if (a.priority === 'Done' && b.priority !== 'Done') {
        return 1;
      } else if (a.priority !== 'Done' && b.priority === 'Done') {
        return -1;
      }

      // If priorities are equal, sort by deadline (earlier deadlines first)
      if (a.priority === b.priority) {
        const deadlineA = new Date(a.deadline).getTime();
        const deadlineB = new Date(b.deadline).getTime();
        return deadlineA - deadlineB;
      }

      return priorityOrder[a.priority] - priorityOrder[b.priority];
    }

    // If only one task is Done, move it to the end
    return a.status === 'Done' ? 1 : -1;
  });
  setTasks(sortedTasks);
};



  const sortByStatus = () => {
    const statusOrder = { 'to complete': 1, 'in progress': 2, Done: 3 };
    const sortedTasks = [...tasks].sort((a, b) => {
      return statusOrder[a.status] - statusOrder[b.status];
    });
    setTasks(sortedTasks);
  };



  const sortByProject = () => {
    const sortedTasks = [...tasks].sort((a, b) => {
      // Check if either a or b has undefined or null project
      if (!a.project || !b.project) {
        // Place tasks with undefined or null project at the end
        if (!a.project && !b.project) {
          return 0;
        } else if (!a.project) {
          return 1;
        } else {
          return -1;
        }
      }
      // Compare project names
      return a.project.localeCompare(b.project);
    });
    setTasks(sortedTasks);
  };

  

  const handleSortByDeadline = () => sortByDeadline();
  const handleSortByPriority = () => sortByPriority();
  const handleSortByStatus = () => sortByStatus();
  const handleSortByProject = () => sortByProject();

  const handleGetBossHelp = () => {
    setIsChatboxOpen(true);
  };

  const handleCloseChatbox = () => {
    setIsChatboxOpen(false);
  };

  const handleAIResponse = (response) => {
    const index1 = response.search(/1:|1\./); //or 1: or 1. - cause chatgpt is not understanding my task format of 1:
    
    // Check if the response contains '1:' or '1.'
    if (index1 !== -1) {
        const index2 = response.search(/2:|2\./);
        const index3 = response.search(/3:|3\./);
        const index4 = response.search(/4:|4\./);
        const index5 = response.search(/5:|5\./);
        const index6 = response.search(/6:|6\./);

        const contentBetween1And2 = response.substring(index1 + 3, index2).trim();
        const contentBetween2And3 = response.substring(index2 + 3, index3).trim();
        const contentBetween3And4 = response.substring(index3 + 3, index4).trim();
        const contentBetween4And5 = response.substring(index4 + 3, index5).trim();
        const contentBetween5And6 = response.substring(index5 + 3, index6).trim();

        const suggestedTasks = [
            { id: 1, task: contentBetween1And2, status: 'to complete' },
            { id: 2, task: contentBetween2And3, status: 'to complete' },
            { id: 3, task: contentBetween3And4, status: 'to complete' },
            { id: 4, task: contentBetween4And5, status: 'to complete' },
            { id: 5, task: contentBetween5And6, status: 'to complete' },
        ];

        // Update aiGeneratedTasks state with the new tasks
        setAIGeneratedTasks(suggestedTasks);
    }
};


const AITaskGenerator = async () => {
  try {
    // Retrieve the last AI response from the messages array
    const lastAIResponse = messages[messages.length - 1];

    console.log("AITaskGenerator function called");
    console.log("lastAIResponse:", lastAIResponse);

    // Now directly call handleAIResponse with the last AI response
    handleAIResponse(lastAIResponse);
  } catch (error) {
    console.error('Error simulating AI response:', error);
  }
};


  const handleAddAIGeneratedTask = async (aiTask) => {
    try {
      // Access the "tasks" collection using firestore.collection
      const tasksCollection = collection(firestore, 'tasks');
    
      // Generate a unique ID for the AI-generated task
      const taskId = uuidv4();
    
      // Get the current user's identifier
      const currentUserIdentifier = currentUser ? currentUser.email : null;
    
      // Add the AI-generated task to Firestore with the user's identifier
      await setDoc(doc(tasksCollection, taskId), { ...aiTask, deadline: new Date().toISOString().split('T')[0], user: currentUserIdentifier, id: taskId });
    
      // Add the AI-generated task to the local state with the generated ID
      const updatedTasks = [
        ...tasks,
        { ...aiTask, deadline: new Date().toISOString().split('T')[0], user: currentUserIdentifier, id: taskId }
      ];
      setTasks(updatedTasks);
    } catch (error) {
      console.error('Error adding AI-generated task: ', error);
    }
  }

  const toggleShowDoneTasks = () => {
    setShowDoneTasks(!showDoneTasks);
  };

  const isTaskOverdue = (task) => {
    if (task.deadline) {
      const deadlineDate = new Date(task.deadline);
      const currentDate = new Date();
      return deadlineDate < currentDate && (task.status === 'to complete' || task.status === 'in progress');
    }
    return false;
  };

  
  const fetchTasks = async (currentUser) => {
    try {
      console.log('Fetching tasks...');
      if (!currentUser) {
        console.log('No current user. Skipping task fetch.');
        return;
      }

    const tasksCollection = collection(firestore, 'tasks');
    const querySnapshot = await getDocs(tasksCollection);
    console.log('Query snapshot:', querySnapshot);

    // Map the document snapshots to tasks
    const tasksData = querySnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter((task) => task.user === currentUser.email);
    console.log('Filtered tasks data:', tasksData);

    // Sort tasks by Done status
    const sortedTasks = tasksData.sort((a, b) => {
      if (a.status === 'Done' && b.status !== 'Done') {
        return 1; // Sort Done tasks to the bottom
      } else if (a.status !== 'Done' && b.status === 'Done') {
        return -1; // Sort non-Done tasks to the top
      }
      return 0; // No change in order for tasks with the same Done status
    });

    console.log('Sorted tasks:', sortedTasks);
    setTasks(sortedTasks); // Set the sorted tasks in state
  } catch (error) {
    console.error('Error fetching tasks: ', error);
    // Handle the error here
  }
};


return (
  <div>
    <Header />
    <div className="task-list-container">
      <h1>Steps to conquer</h1>
      <h4 className="points">Your Points: {userPoints}</h4>
      <p className="subtext">Let´s accomplish your goals</p>
      <div className="sorting-controls">
  <div className="dropdown">
    <button className="options-button">Sort by</button>
    <div className="dropdown-content">
      <button onClick={handleSortByDeadline}>Deadline</button>
      <button onClick={handleSortByPriority}>Priority</button>
      <button onClick={handleSortByStatus}>Status</button>
      <button onClick={handleSortByProject}>Project</button>
    </div>
  </div>
  <button onClick={() => setShowDoneTasks(!showDoneTasks)}>Filter Done</button>
</div>


      <form onSubmit={handleAddTask}>
        <div className="add-task-container">
          <input
            type="text"
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
            placeholder="Enter a new task"
            className="task-input"
          />
          <button type="submit" className="add-task-button">
            Add Task
          </button>
        </div>
      </form>

     
      {/* Task Items */}
     
{tasks
  .filter((task) => showDoneTasks || task.status !== 'Done')
  .map((task, index) => (
    <div className={`task-item card ${task.status === 'Done' ? 'done' : ''} ${isTaskOverdue(task) ? 'task-overdue' : ''}`} key={task.id}>
      {task.isEditing ? (
        <>
          <input
            type="text"
            value={task.task}
            onChange={(e) => handleEditTaskName(e.target.value, index)}
            className="task-name-input"
          />
          <input
            type="date"
            value={task.deadline}
            onChange={(e) => handleEditDeadline(e.target.value, index)}
            className="deadline-input"
          />
          <div className="task-details">
            <select
              value={task.priority}
              onChange={(e) => {
                const updatedTasks = [...tasks];
                updatedTasks[index].priority = e.target.value;
                setTasks(updatedTasks);
              }}
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
            <select
              value={task.status}
              onChange={(e) => {
                const updatedTasks = [...tasks];
                updatedTasks[index].status = e.target.value;
                setTasks(updatedTasks);
              }}
            >
              <option value="to complete">To Complete</option>
              <option value="in progress">In Progress</option>
              <option value="Done">Done</option>
            </select>
          </div>
          <input
            type="text"
            value={task.project}
            onChange={(e) => {
              const updatedTasks = [...tasks];
              updatedTasks[index].project = e.target.value;
              setTasks(updatedTasks);
            }}
            placeholder="Project"
            className="project-input"
          />
          <div className="options dropdown">
            <button className="options-button">Options</button>
            <div className="dropdown-content">
              <button onClick={() => handleEditTask(index)} className="edit-task-button">
                Edit
              </button>
              <button onClick={() => handleDeleteTask(index)} className="delete-task-button">
                Delete
              </button>
            </div>
          </div>
          <button onClick={() => handleSaveTask(index)}>Save</button>
          <button onClick={() => handleCancelEdit(index)}>Cancel</button>
        </>
      ) : (
        <>
          <div className="task-name" onClick={() => handleEditTask(index)}>{task.task}</div>
         
          <div className="task-details">
          
            <div className="priority" onClick={() => handleEditTask(index)}>{task.priority}</div>
            <div className="deadline" onClick={() => handleEditTask(index)}>{task.deadline || 'Set deadline'}</div>
           
          </div>
          <div className="task-details">
          
          
          <div className="status" onClick={() => handleEditTask(index)}>{task.status}</div>
          <div className="project" onClick={() => handleEditTask(index)}>{task.project}</div>
         
        </div>
          <div> 
            
            
            
            <div className="options dropdown">
            <button className="options-button">Options</button>
            <div className="dropdown-content">
              <button onClick={() => handleEditTask(index)} className="edit-task-button">
                Edit
              </button>
              <button onClick={() => handleDeleteTask(index)} className="delete-task-button">
                Delete
              </button>
            </div>
          </div></div>
         
          
        </>
      )}
    </div>
  ))}



    </div>
    {/* Chatbox and AI Generated Tasks */}
    <button onClick={handleGetBossHelp} className="prompt-boss-button">
      Get Boss Help
    </button>
    {isChatboxOpen && (
      <div>
        <div className="chatbox">
          {messages.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </div>
        <div className="chatbox-input">
          <input type="text" value={inputMessage} onChange={(e) => setInputMessage(e.target.value)} />
          <button onClick={handleSendMessage} className="ai-options-button send-button">
            Send
          </button>
          <button onClick={handleCloseChatbox} className="ai-options-button close-button">
            Close
          </button>
        </div>
      </div>
    )}

    {isChatboxOpen && (
      <div>
        <button onClick={AITaskGenerator} className="add-task-button">
          Generate Tasks
        </button>
        <ul>
          {aiGeneratedTasks.map((task) => (
            <li key={task.id}>
              {task.task}{' '}
              <button onClick={() => handleAddAIGeneratedTask(task)} className="add-task-button">
                Add
              </button>
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);
  
}

export default TaskListPageWorking;





