
import React from 'react';
import SignUp from '../components/SignUp';
import Header from '../components/Header.js'; 

function SignUpPage() {
  return (
    <div>
       <Header/>
       <div className="signin-page-container">
    
      <SignUp />
      </div>
    </div>
  );
}

export default SignUpPage;
