import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { auth } from './firebaseConfig.js';
import { createUserWithEmailAndPassword } from "firebase/auth";
import "./SignIn.css"


function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signUpError, setSignUpError] = useState(null); // State to hold sign-up error
  const [userCreated, setUserCreated] = useState(false); // State to track if user is created

  const handleSignUp = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // New user signed up successfully
        const user = userCredential.user;
        console.log("New user:", user);
        // Update state to indicate user is created
        setUserCreated(true);
      })
      .catch((error) => {
        // Handle sign-up errors
        console.error("Error signing up:", error);
        setSignUpError(error.message); // Set sign-up error message in state
      });
  };

  return (
    <div class="signin-form">
       
      {signUpError && <p>{signUpError}</p>} {/* Display sign-up error message if any */}
      <h1 className="title">A new way to keep your tasks on date</h1>
      <h3 className="subtitle">Sign up, it's free</h3>
      <div>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="signin-input"/>
      </div>
      <div><input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="signin-input" />
      </div><button onClick={() => handleSignUp(email, password)} className="signin-button">Sign Up</button>
      {/* Conditionally render Link to tasks page after user is created */}
      {userCreated && <Link to="/tasks">Go to Tasks</Link>}
    </div>
  );
}

export default SignUp;
