// Header.js
import React from 'react';
import "./Header.css"

function Header() {
    return (
        <header className="header">
            <a href="/">
                <img src="https://i.imgur.com/BidwL4C.png" alt="Company Logo" className="logo" />
            </a>

            <nav>
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/tasks">Tasks</a></li>
                    <li><a href="/tasks2">Tasks2</a></li>
                    <li><a href="/signin">Login</a></li>
                    <li><a href="/signup">Sign Up</a></li>

                    
                </ul>
            </nav>
        </header>
    );
}

export default Header;
